<!-- 首页 -->
<template>
  <div>
    <el-button type="primary" plain>主要按钮</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {}
};
</script>
<style lang='scss' scoped>
</style>